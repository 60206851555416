import React from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';

import Button from '@heureca/shared/components/Button';
import Input from '@heureca/shared/components/Input';
import IFormValues from '@heureca/shared/models/IFormValues.model';
import PreRegistration from '@heureca/signUp/models/PreRegistration.model';
import Steps from '@heureca/shared/components/Steps';
import routeConstants from '@heureca/signUp/constants/route.constants';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import useSignUp from '@heureca/signUp/hooks/useSignUp.hook';
import Layout from '@heureca/shared/components/Layout';
import analyticsUtils from '@heureca/shared/utils/analytics.utils';

import * as styles from './addressStep.module.scss';
import SignUpSectionCard from '../SignUpSectionCard';

const AddressStep = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const { translate } = useTranslations();
  const { signUp, updateSignUp } = useSignUp(() => navigate(routeConstants.CONTACT));
  const { value } = signUp.address;

  const onNext = ({ address }: Partial<PreRegistration>) => {
    analyticsUtils.addEvent({
      event: 'Address step completed',
    });

    updateSignUp({
      address: {
        valid: true,
        value: {
          ...address,
          country: signUp.address.value.country,
        },
      },
    });
  };

  return (
    <Layout pageTitle={translate('signUp.title')} isSignUp>
      <SignUpSectionCard
        name="address-data"
        title={translate('signUp.steps.address')}
      >
        <Steps currentStep={2} />
        <form className={styles.form} onSubmit={handleSubmit(onNext)}>
          <Input
            label={translate('signUp.street')}
            placeholder="Ex: Rue Comte Cornet"
            wrapperClassName={styles.input}
            name="address.street"
            register={register}
            required
            error={errors.address?.street}
            customErrorMessage={translate('signUp.streetError')}
            value={value?.street}
          />
          <Input
            label={translate('signUp.streetNumber')}
            placeholder="Ex: 19"
            wrapperClassName={styles.input}
            register={register}
            name="address.streetNumber"
            required
            error={errors.address?.streetNumber}
            customErrorMessage={translate('signUp.streetNumberError')}
            value={value?.streetNumber}
          />
          <Input
            label={translate('signUp.box')}
            placeholder="Ex: A"
            wrapperClassName={styles.input}
            register={register}
            name="address.streetNumber"
            error={errors.address?.box}
            value={value?.box}
          />
          <Input
            label={translate('signUp.postCode')}
            placeholder="Ex: 7020"
            wrapperClassName={styles.input}
            name="address.postCode"
            register={register}
            required
            error={errors.address?.postCode}
            customErrorMessage={translate('signUp.postCodeError')}
            value={value?.postCode}
          />
          <Input
            label={translate('signUp.city')}
            placeholder="Ex: Maisières"
            wrapperClassName={styles.input}
            name="address.locality"
            register={register}
            required
            error={errors.address?.locality}
            customErrorMessage={translate('signUp.cityError')}
            value={value?.locality}
          />
          <div className={styles.buttonWrapper}>
            <Button
              label={translate('signUp.next')}
              type="submit"
            />
          </div>
        </form>
      </SignUpSectionCard>
    </Layout>
  );
};

export default AddressStep;
